h1 {
  margin: 0;
}

h2 {
  font-size: 22px;
}

h3 {
  margin: 0;
  font-size: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body.printingContent > *{
    display: none !important; /* hide everything in body when in print mode*/
}

.printContainer {
    display: block !important; /* Override the rule above to only show the printables*/
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
} 

@media print {
  #dialogActions {
    display: none;
  }
  .MuiPaper-root .MuiDialog-paper .MuiDialog-paperScrollPaper .MuiDialog-paperWidthSm .MuiPaper-elevation24 .MuiPaper-rounded {
    max-width: none;
    max-height: none;
    margin: 0;
  }
  html, body {
    height:100%; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
  }
}